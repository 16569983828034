module.exports = {
    siteUrl: `https://underthehoodlearning.com`, // Site domain. Do not include a trailing slash!

    postsPerPage: 12, // Number of posts shown on paginated pages (changes this requires sometimes to delete the cache)

    siteTitleMeta: `Under The Hood Learning`, // This allows an alternative site title for meta data for pages.
    siteDescriptionMeta: `Learning to share, sharing to learn`, // This allows an alternative site description for meta data for pages.

    shareImageWidth: 1000, // Change to the width of your default share image
    shareImageHeight: 523, // Change to the height of your default share image

    shortTitle: `Under The Hood Learning`, // Used for App manifest e.g. Mobile Home Screen
    siteIcon: `favicon.png`, // Logo in /static dir used for SEO, RSS, and App manifest
    backgroundColor: `#35bc9b`, // Used for Offline Manifest
    themeColor: `#35bc9b`, // Used for Offline Manifest
}
